import React from "react"
import { graphql } from 'gatsby'
// import { useStaticQuery, graphql } from "gatsby"
// import SEO from "../components/seo"
import {Link} from "gatsby"
// import Logo from "../components/logo"
import languageFromPath from "../api/languageFromPath"
import getTranslationTexts from "../api/getTranslationTexts"
import SEO from "../components/seo"
import TopMenu from "../components/topMenu"
import Button from "../components/buttons/button"

const page404 = (props) => {
  const language = languageFromPath(props.location.pathname);
  const translations = props.data.translations.childMdx.frontmatter.languages;
  const translation = getTranslationTexts(language, translations);
  const languages = translations.map((item)=>item.language.code);
  const settings = props.data.settings.childMdx.frontmatter;
  // console.log(translation);

  return (
    <section className={"page lang-" + language}>
    <SEO
      title={translation.page404.title}
    />
  <TopMenu smallLogo={true} lang={language} languages={languages} slug="/" translation={translation} settings={settings}></TopMenu>
    <div className="page404">
      <div className="page404-in section tac">
        <div className="color-accent-3 mb2">
          
        </div>
        <h2 className="mb2">{translation.page404.text}</h2>
        <Link to={translation.nav[0].url} className="btn">
          <Button color="3">{translation.nav[0].title}</Button>
        </Link>
        </div>
      </div>
  </section>

   
  )

}

export const query404 = graphql`
  query query404 {
    translations: file(sourceInstanceName: {eq: "settings"}, name: {eq: "translations"}) {
      childMdx {
        frontmatter {
          languages {
            language {
              code
              title
              footer {
                contacts
                news
                links
                privacy
              }
              nav {
                title
                url
              }
              page404 {
                title
                text
              }
            }
          }
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          detectLanguage
          address
          address2
          phone
          instagram
          facebook
          pinterest
          googlemaplink
          email
        }
      }
    }
  }
`

export default page404
